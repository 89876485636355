<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="450px"
    @keydown.esc="dialog = false"
  >
    <template v-slot:activator="{ on }">
      <v-list-item v-on="on">
        <v-list-item-action class="mr-4">
          <v-icon class="mx-2">mdi-key-outline</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Change Password</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <v-card outlined>
      <v-card-title class="px-5">Change Password</v-card-title>
      <v-card-text class="pa-5 pt-2">
        <v-form v-model="valid" ref="form" @submit.prevent="validateAndSave()">
          <v-text-field
            v-model="model.currentPassword"
            dense
            outlined
            label="Current Password"
            type="password"
            :error-messages="currentPasswordErrors"
            @blur="$v.model.currentPassword.$touch()"
          ></v-text-field>
          <v-text-field
            v-model="model.newPassword"
            dense
            outlined
            label="New Password"
            type="password"
            :error-messages="newPasswordErrors"
            @blur="$v.model.newPassword.$touch()"
          ></v-text-field>
          <v-text-field
            v-model="model.confirmPassword"
            dense
            outlined
            label="Confirm Password"
            type="password"
            :error-messages="confirmPasswordErrors"
            @blur="$v.model.confirmPassword.$touch()"
          ></v-text-field>
          <v-btn depressed color="primary" type="submit" :loading="loading">
            Save
          </v-btn>
          <v-btn
            outlined
            text
            class="mx-3"
            :disabled="loading"
            @click="reset()"
          >
            Cancel
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { required, sameAs, minLength } from 'vuelidate/lib/validators';

export default {
  data() {
    return {
      dialog: false,
      loading: false,
      model: {},
      valid: false,
    };
  },

  validations() {
    return {
      model: {
        currentPassword: { required },
        newPassword: { required, minLength: minLength(8) },
        confirmPassword: { sameAs: sameAs('newPassword') },
      },
    };
  },

  computed: {
    currentPasswordErrors() {
      const errors = [];
      if (!this.$v.model.currentPassword.$dirty) return errors;
      !this.$v.model.currentPassword.required &&
        errors.push('Current Password is required');
      return errors;
    },

    newPasswordErrors() {
      const errors = [];
      if (!this.$v.model.newPassword.$dirty) return errors;
      !this.$v.model.newPassword.required &&
        errors.push('New Password is required');
      !this.$v.model.newPassword.minLength &&
        errors.push('Password must have at least 8 characters');
      return errors;
    },

    confirmPasswordErrors() {
      const errors = [];
      if (!this.$v.model.confirmPassword.$dirty) return errors;
      !this.$v.model.confirmPassword.sameAs &&
        errors.push('Password confirmation does not match');
      return errors;
    },
  },

  methods: {
    reset() {
      this.dialog = false;
      this.model = {};
      this.$v.$reset();
    },

    validateAndSave() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return false;
      }

      this.loading = true;

      this.$axios
        .post(`users/${this.$store.state.user.id}/change-password`, this.model)
        .then(() => {
          this.reset();
          this.$root.$toast.open('Password updated.', 'success');
        })
        .catch(() => {
          this.$root.$toast.open('There was an error!', 'error');
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
