<template>
  <div>
    <v-app-bar app clipped-left tile>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>{{ $appName }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-title
        v-if="$vuetify.breakpoint.smAndUp && user"
        class="body-1 pt-1 pr-4"
      >
        {{ user.name }}
      </v-toolbar-title>
      <v-menu
        bottom
        left
        offset-y
        min-width="250"
        transition="scroll-y-transition"
      >
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon>
            <v-avatar color="accent" size="40">
              <span v-if="user" class="white--text font-weight-bold body-1">
                {{ userInitials }}
              </span>
            </v-avatar>
          </v-btn>
        </template>
        <v-list class="pa-0">
          <v-list-item v-if="user">
            <v-list-item-action class="mr-4">
              <v-avatar color="accent" size="40">
                <span class="white--text font-weight-bold body-1">
                  {{ userInitials }}
                </span>
              </v-avatar>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ user.name }}</v-list-item-title>
              <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <change-password></change-password>
          <v-list-item @click="switchTheme">
            <v-list-item-action class="mr-4">
              <v-icon class="mx-2">mdi-theme-light-dark</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Switch to {{ theme }} theme
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="logout">
            <v-list-item-action class="mr-4">
              <v-icon class="mx-2">mdi-logout</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" app clipped hide-overlay>
      <v-list shaped>
        <v-list-item :to="{ name: 'dashboard' }" exact>
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <template v-for="route in routes">
          <v-list-item :key="route.text" :to="route.path">
            <v-list-item-icon>
              <v-icon>{{ route.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ route.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid>
        <v-card>
          <router-view />
        </v-card>
      </v-container>
    </v-main>

    <confirm ref="confirm"></confirm>
    <toast ref="toast"></toast>
  </div>
</template>

<script>
import ChangePassword from './Users/ChangePassword';

export default {
  name: 'Admin',

  components: {
    ChangePassword,
  },

  data: () => ({
    drawer: null,
    routes: [
      {
        icon: 'mdi-bullhorn-outline',
        text: 'Promotions',
        path: { name: 'promotion-list' },
        color: 'green',
      },
      {
        icon: 'mdi-account-multiple-outline',
        text: 'Users',
        path: { name: 'user-list' },
        color: 'teal',
      },
    ],
  }),

  computed: {
    user() {
      return this.$store.state.user;
    },

    userInitials() {
      const name = this.$store.state.user.name.split(' ');
      const initials = name.shift().charAt(0) + (name.pop()?.charAt(0) || '');
      return initials.toUpperCase();
    },

    theme() {
      return this.$vuetify.theme.dark ? 'light' : 'dark';
    },
  },

  mounted() {
    this.$root.$confirm = this.$refs.confirm;
    this.$root.$toast = this.$refs.toast;
  },

  methods: {
    async logout() {
      await this.$axios.post('auth/logout');
      this.$store.dispatch('logout');
      this.$router.replace({ name: 'login' });
    },

    switchTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem(
        'theme',
        this.$vuetify.theme.dark ? 'dark' : 'light'
      );
    },
  },
};
</script>
